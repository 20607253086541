var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Overlay",
    { attrs: { show: _vm.show } },
    [
      _c(
        "VCard",
        { staticClass: "BillingInfoModal" },
        [
          _c("h1", { staticClass: "text-h6" }, [_vm._v("Billing Info")]),
          _c("VTextField", {
            staticClass: "Account-input",
            attrs: { label: "Company Name", filled: "" }
          }),
          _c("VTextField", {
            staticClass: "Account-inner-input",
            attrs: {
              "prepend-inner-icon": "mdi-map-marker",
              label: "Billing Address",
              filled: ""
            }
          }),
          _c(
            "div",
            { staticClass: "contained-inputs" },
            [
              _c("VTextField", {
                staticClass: "Account-inner-input mr-2",
                attrs: { label: "City", filled: "" }
              }),
              _c("VTextField", {
                staticClass: "Account-inner-input ml-2",
                attrs: { label: "ZIP", filled: "" }
              })
            ],
            1
          ),
          _c("VTextField", {
            staticClass: "Account-inner-input",
            attrs: { label: "Country", filled: "" }
          }),
          _c("VTextField", {
            staticClass: "Account-inner-input",
            attrs: { label: "VAT Number", filled: "" }
          }),
          _c(
            "div",
            { staticClass: "BillingInfoModal-buttons" },
            [
              _c(
                "VBtn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("hide-modal")
                    }
                  }
                },
                [_vm._v("CANCEL")]
              ),
              _c("VBtn", { attrs: { color: "primary" } }, [_vm._v("UPDATE")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }