import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import BillingInfoModal from './Billing/BillingInfoModal.vue';
var Billing = (function (_super) {
    __extends(Billing, _super);
    function Billing() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showBillingInfo = false;
        _this.selected = [];
        _this.headers = [
            {
                text: 'Month',
                align: 'start',
                sortable: false,
                value: 'month'
            },
            { text: 'Amount', align: 'end', value: 'amount', sortable: false }
        ];
        _this.desserts = [
            {
                month: 'November',
                year: 2020,
                amount: '0'
            },
            {
                month: 'December',
                year: 2020,
                amount: '0'
            }
        ];
        return _this;
    }
    Billing = __decorate([
        Component({
            components: { BillingInfoModal: BillingInfoModal }
        })
    ], Billing);
    return Billing;
}(Vue));
export default Billing;
