var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Billing" },
    [
      _c("div", { staticClass: "Billing-header" }, [
        _c("h1", { staticClass: "text-h6" }, [_vm._v("Usage summary")]),
        _c(
          "a",
          {
            on: {
              click: function($event) {
                _vm.showBillingInfo = true
              }
            }
          },
          [_vm._v("Edit Billing Info")]
        )
      ]),
      _vm.selected.length
        ? _c("div", { staticClass: "Billing-items-selected" }, [
            _c("div", [
              _vm._v(
                _vm._s(_vm.selected.length) + " items selected to download"
              )
            ]),
            _c(
              "div",
              { staticClass: "items-actions" },
              [
                _c(
                  "VIcon",
                  {
                    staticClass: "mr-2 clickable",
                    attrs: { color: "#2196f3" }
                  },
                  [_vm._v("mdi-printer")]
                ),
                _c(
                  "VIcon",
                  { staticClass: "clickable", attrs: { color: "#2196f3" } },
                  [_vm._v("mdi-download")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("VDataTable", {
        staticClass: "elevation-1",
        attrs: {
          dense: "",
          headers: _vm.headers,
          items: _vm.desserts,
          "single-select": false,
          "item-key": "month",
          "show-select": ""
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("BillingInfoModal", {
        attrs: { show: _vm.showBillingInfo },
        on: {
          "hide-modal": function($event) {
            _vm.showBillingInfo = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }