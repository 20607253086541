import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Overlay from '@/components/common/Overlay.vue';
var BillingInfoModal = (function (_super) {
    __extends(BillingInfoModal, _super);
    function BillingInfoModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop()
    ], BillingInfoModal.prototype, "show", void 0);
    BillingInfoModal = __decorate([
        Component({
            components: { Overlay: Overlay }
        })
    ], BillingInfoModal);
    return BillingInfoModal;
}(Vue));
export default BillingInfoModal;
